/* Specify box sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove internal indents */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove external indents */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

/* Set basic default settings for body */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: var(--background) !important;
 }

/* Remove standard stylization for all ul and li that have a class */
ul[class],
ol[class] {
  list-style: none;
}

/* Elements a that do not have a class are reset to default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Simplify image handling */
img {
  max-width: 100%;
  display: block;
}

/* Specify a clear periodicity in the data flow of article */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations and transitions for people who prefer not to use them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/* Styles for Date picker, as it does not work with styled components*/
.PrivatePickersToolbar-root {
  background-color: var(--primary);
}
.PrivatePickersToolbar-root span, .PrivatePickersToolbar-root h4  {
  color:  var(--white);
}
.PrivatePickersToolbar-root .PrivateDatePickerToolbar-penIcon  {
  display: none !important;
}

#stripe-body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
}

#stripe-form {
  width: 100%;
  align-self: center;
  box-shadow: 0 0 0 1px rgba(121, 85, 162, 1),
    0 2px 5px 0 rgba(50, 50, 93, 0.1), 0 1px 1.5px 0 rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
#stripe-button {
  background: var(--primary);
  font-family: Arial, sans-serif;
  color: var(--accent);
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
  width: 100%;
}

#stripe-button:hover {
  filter: contrast(115%);
}

#stripe-button:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: var(--white);
  font-size: 22px;
  text-indent: -99999px;
  margin: 0 auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0 10.2px;
  transform-origin: 0 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

.hide-chatwood {
  display: none;
}

.woot--bubble-holder button {
  width: 56px !important;
  height: 56px !important;
  background: var(--primaryText) !important;
}

.woot--bubble-holder svg {
  margin: 16px !important;
  width: 22px !important;
  height: 22px !important;
}
.woot--close::before, .woot--close::after {
  top: 17px !important;
  left: 28px !important;

}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}